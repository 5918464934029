import { Card, FlexProps } from "rebass";
import styled from "styled-components";
import {
  flex,
  fontSize,
  FontSizeProps,
  minHeight,
  MinHeightProps,
  space,
  SpaceProps
} from "styled-system";

export const ContactCard = styled(Card)<FlexProps & MinHeightProps>`
  ${flex}
  ${minHeight}
  display: flex;
  box-shadow: ${props => props.theme.shadows.small};
  text-decoration: none;
  color: ${props => props.theme.colors.default};
  max-height: 250px;

  &:hover {
    box-shadow: ${props => props.theme.shadows.normal};
  }
`;

ContactCard.defaultProps = {
  m: [1],
  py: [3, 4],
  px: [2, 4],
  width: ["calc(50% - 1rem)"],
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "10px",
  minHeight: ["180px", "250px"]
};

export const ContactText = styled("span")<FontSizeProps & SpaceProps>`
  ${fontSize}
  ${space}
  text-align: center;
`;

ContactText.defaultProps = {
  fontSize: ["1rem", "18px"]
};
