import React from "react";
import { Icon } from "react-icons-kit";
import { Box, Flex } from "rebass";
import GoogleMap from "../../components/GoogleMap";
import Section from "../../components/Section";
import { contacts } from "../../lib/config";
import { theme } from "../../lib/theme";
import { ContactCard, ContactText } from "./contactSection.style";

const ContactSection = ({ containerProps }) => {
  return (
    <Section {...containerProps}>
      <Flex flexWrap="wrap" width={[1]}>
        <Box width={[1, 1, 1 / 2]} my={1}>
          <GoogleMap
            defaultCenter={{ lat: 22.2834, lng: 114.1578 }}
            defaultZoom={16}
            mapHeight="520px"
            // markers={[
            //   {
            //     coordinates: { latitude: 22.28363, longitude: 114.157104 },
            //     name: "希臘房產投資",
            //   },
            // ]}
          ></GoogleMap>
        </Box>

        <Flex
          flexWrap="wrap"
          mt={[1, 1, 0]}
          pl={[0, 0, 3]}
          width={[1, 1, 1 / 2]}
        >
          {Object.keys(contacts).map((k, i) => (
            <ContactCard key={i} as="a" href={contacts[k].link}>
              <Icon
                icon={contacts[k].icon}
                size={86}
                css={`
                  color: ${theme.colors.info};
                `}
              />
              <ContactText mt={4}>{contacts[k].text}</ContactText>
            </ContactCard>
          ))}
        </Flex>
      </Flex>
    </Section>
  );
};

export default ContactSection;
