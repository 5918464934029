import { graphql } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import { Heading } from "rebass";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Paragraph from "../components/Paragraph";
import Section from "../components/Section";
import SEO from "../components/Seo";
import ContactSection from "../container/ContactSection";

const AboutUsPage = ({ data }) => {
  return (
    <Layout transparentHeader>
      <SEO title="關於我們" />
      <PageHeader backgroundImage={data.bg.childImageSharp.fluid}>
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]}>
            關於我們
          </Heading>
        </Fade>
      </PageHeader>

      <Section>
        <Paragraph>
          <strong>{data.site.siteMetadata.title}</strong>
          ，提供一站式服務包括:
          希臘物業買賣、租務管理、移民顧問、當地考察及投資講座。專業代辦希臘「黃金簽證」，助客戶於當地購入物業後，辦理移民手續，特快獲取希臘永居權。
          <p>
            本公司專業團隊提供一對一諮詢服務，為客戶解答疑問並提供最佳希臘投資移民方案。本公司不定期舉辦希臘物業考察團，讓客戶親身了解當地生活及樓宇狀況。
          </p>
        </Paragraph>
      </Section>

      <ContactSection containerProps={{ pt: 0 }} />
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-7.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
